import {
  ParentComponent,
  Show,
  JSXElement,
  Component,
  createMemo,
  Accessor,
} from "solid-js";
import { useSessionContext } from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { lockClosed, xCircle } from "solid-heroicons/outline";
import { useSearchParams, A, revalidate } from "@solidjs/router";
import { PERMISSION } from "~/services/permissions";

type AuthBarrierProps = {
  fallback?: Component;
  fallbackRoot?: Component<{ children: JSXElement }>;
  permissions?: keyof typeof PERMISSION | (keyof typeof PERMISSION)[];
  requireAny?: boolean;
  // additional?: Accessor<boolean>
};

const DefaultFallback: Component<{ isAuthenticated?: boolean }> = (props) => {
  const [_, setParams] = useSearchParams();

  return (
    <div class="bg-roma-grey border rounded-md p-6 max-w-xl mx-auto my-8 flex flex-col gap-2 w-full">
      <div class="mb-2 pb-2 border-b border-gray-300 flex items-center">
        <Icon
          path={props.isAuthenticated ? xCircle : lockClosed}
          class="w-6 h-6 mr-2"
        />
        <h2 class="text-lg">
          {props.isAuthenticated
            ? "Insufficient Permissions"
            : "Partner-Access Only"}
        </h2>
      </div>

      {props.isAuthenticated ? (
        <>
          <p>You don't have the required permissions to access this page.</p>
          <p>
            Please contact {" "}
            <A href="/support/" class="text-roma-blue">
              Customer Support
            </A>{" "}
            if you believe this is an error.
          </p>
        </>
      ) : (
        <>
          <p>Access to this page is restricted to Roma Partners only.</p>
          <p>
            Please{" "}
            <button
              class="text-roma-blue"
              onClick={() => setParams({ signIn: true })}
            >
              Sign In
            </button>{" "}
            to continue.
          </p>
          <p class="mt-4 text-sm">
            Not a Partner?{" "}
            <A href="/support/partner" class="text-roma-blue">
              Join us!
            </A>
          </p>
        </>
      )}
    </div>
  );
};

export const AuthBarrier: ParentComponent<AuthBarrierProps> = (props) => {
  const { isPartner, permission } = useSessionContext();

  // TODO - permission don't seem reactive...

  const isAuthenticated = createMemo(() => isPartner());
  const hasPermission = createMemo(() => {
    if (!props.permissions) return true;
    if (!permission || !isAuthenticated()) return false;

    const permissionArray = Array.isArray(props.permissions)
      ? props.permissions
      : [props.permissions];

    if (props.requireAny) {
      return permissionArray.some((perm) => permission[perm]);
    }
    return permissionArray.every((perm) => permission[perm]);
  });

  const renderFallback = () => {
    const FallbackComponent = props.fallback ?? DefaultFallback;

    if (props.fallbackRoot) {
      const Root = props.fallbackRoot;
      return (
        <Root>
          <FallbackComponent isAuthenticated={isAuthenticated()} />
        </Root>
      );
    }
    return <FallbackComponent isAuthenticated={isAuthenticated()} />;
  };

  return (
    <Show
      when={isAuthenticated() && hasPermission()}
      fallback={renderFallback()}
    >
      {props.children}
    </Show>
  );
};
